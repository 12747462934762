/* Default (light mode) variables */
:root {
    --background-color: white;
    --text-color: black;
    --card-background-color: #f9f9f9;
    --card-border-color: #ccc;
    --header-color: black;
    --icon-color: black;
}

/* Dark mode variables */
@media (prefers-color-scheme: dark) {
    :root {
        --background-color: #121212;

        --text-color: white;

        --card-background-color: #1e1e1e;

        --card-border-color: #333;

        --header-color: white;
        --icon-color: white;

    }
}