/* import a variables css file */

@import 'css/variables.css';
@import 'css/sliders.css';

@font-face {
  font-family: 'Billabong';
  src:
    /* url('./fonts/billabong_regular.otf') format('opentype'), */
    url('./fonts/billabong2.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  position: relative;
  background-color: var(--background-color);
  color: var(--text-color);
}

h1 {
  font-family: 'Billabong';
  font-size: 5rem;
  font-weight: 100;
  margin: 20px;
}



.feed {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;

}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100vw;
  max-width: 600px;


}

.card-header {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: 20px;
  width: 100%;
  padding: 10px;

  /* border-bottom: 1px solid #e6e6e6; */
}

.card-header h3 {

  margin: 0;
  font-size: 1.5em;
  cursor: pointer;
}

.card-content {
  /* padding: 20px; */
  /* border: red 1px solid; */
  display: flex;
  align-items: center;
  font-size: 2rem;
  justify-content: center;
  width: 100%;
  color: white;
  /* Ensuring the content area is a square */

  /* randomly generated linear gradient */
  background: linear-gradient(45deg, #424242, #131313);
  text-align: center;

}

.full {


  padding: 20px;

}

.card-image {
  max-width: 100%;
  max-height: 100%;
}

.card-icons {
  margin-left: 20px;
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  /* Align icons vertically */
  gap: 20px;
  /* Set a small gap between the icons (adjust as needed) */
}

.card-icons svg {
  margin: 0;
  /* Remove any default margin */
  padding: 0;
  /* Remove any default padding */
  width: 24px;
  /* Set the width of the icons */
  height: 24px;
  /* Set the height of the icons */
  display: inline-block;
}

.card-caption {
  width: 100%;
  padding: 10px;
  font-size: 1.5rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu {
  position: fixed;
  display: flex;
  justify-content: space-around;
  background-color: var(--background-color);
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  padding: 20px
}

.emoji {
  font-size: 20rem;
}

.question {
  font-size: 4rem;
  margin: 20px;
}

.answer {
  font-size: 2.5rem;
  margin: 20px;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.avatar-placeholder {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  /* orange to purple gradients */
  background: linear-gradient(45deg, #f093fb, #f5576c);
}

.card-placeholder {
  width: 100vw;
  max-width: 600px;
  height: 100vw;
  max-height: 600px;
  background: linear-gradient(45deg, #8b8b8b, #1e1e1e);
}

.menu-item {
  text-decoration: none;
  /* Remove underline */
  color: inherit;
  /* Ensure it uses currentColor */
}

.menu-item svg {
  stroke: currentColor;
  fill: none;
  transition: fill 0.3s ease, stroke 0.3s ease;
}

.menu-item.active svg {
  fill: currentColor;
  stroke: currentColor;
  /* Use currentColor for both stroke and fill */
}

.feed-toggle {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 20px;
  margin: 20px;
}

/* Skeleton placeholder container */
.skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px;
  /* background-color: #f3f3f3; */
}

/* Big square for the content */
.skeleton-square {
  width: 100vw;
  height: 100vw;
  max-width: 600px;
  /* Adjust this to fit your layout */
  max-height: 600px;
  /* Adjust this to fit your layout */
  background-color: #e0e0e0;
  border-radius: 8px;
  animation: shimmer 1.5s infinite;
}

/* Caption placeholder */
.skeleton-caption {
  width: 100%;
  /* Adjust width as needed */
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: shimmer 1.5s infinite;
}

/* Keyframes for the shimmer effect */
@keyframes shimmer {
  0% {
    background-position: -500px 0;
  }

  100% {
    background-position: 500px 0;
  }
}

/* Shimmer effect applied to skeleton elements */
.skeleton-square,
.skeleton-caption {
  background: linear-gradient(90deg,
      #e0e0e06f 25%,
      #f8f8f87c 50%,
      #e0e0e05f 75%);
  background-size: 500px 100%;
}

.carousel-container {

  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vw;
  max-width: 600px;
  max-height: 600px;
}

.carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
}

.carousel::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar */
}

.slide {
  flex: 0 0 100%;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f0f0f0; */

}

.dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  /* Ensure spacing from the icons */
}

.dot {
  height: 6px;
  width: 6px;
  margin: 0 5px;
  background-color: grey;
  border-radius: 50%;
}

.dot.active {
  background-color: rgb(0, 119, 255);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.flag {
  font-size: 20rem;
}

.back {
  margin: 0;
  /* Remove any default margin */
  padding: 0;
  /* Remove any default padding */
  width: 24px;
  /* Set the width of the icons */
  height: 24px;
  /* Set the height of the icons */
  display: inline-block;
}

.profile-header {
  display: flex;
  background-color: var(--background-color);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
  justify-content: space-between;
  padding: 20px;
}

.profile-header__info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-header__posts {
  font-size: 1.6rem;
  font-weight: bold;
}

.author {
  margin-top: 20px;
  font-size: 1.6rem;
  font-weight: bold;
}

.content {
  padding: 60px;
}