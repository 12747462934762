.configure {
    display: flex;
    justify-content: center;
    align-items: center;
}

.feed-toggle {

    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
}

.feed-toggle h2 {

    text-align: center;

    margin-bottom: 20px;
}

.feed-toggle-item {}

.feed-toggle-item label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
    /* Ensures text and switch stay in one line */

}

.feed-toggle-item .switch {
    margin-left: auto;
    /* Ensures the switch stays on the right */
    display: inline-block;
    width: 45px;
    height: 24px;
    position: relative;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 24px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #4caf50;
}

input:checked+.slider:before {
    transform: translateX(20px);
}

.slider.round {
    border-radius: 24px;
}